var jquery;
window.jQuery = jquery = $ = require('jquery');
//var sassToJs = require('sass-to-js');

require('../../ground/scripts/buds/gr-snippets.js');
require('../../ground/scripts/buds/gr-equalizer.js');
require('../../ground/scripts/buds/gr-form.js');
require('../../ground/scripts/buds/gr-inline-svg.js');
require('../../ground/scripts/buds/gr-lazy-loader.js');
require('../../ground/scripts/buds/gr-nav.js');
require('../../ground/scripts/buds/gr-objectfit.js');
require('../../ground/scripts/buds/gr-modal.js');
require('../../ground/scripts/buds/gr-expander.js');
require('../../ground/scripts/buds/gr-googlemap.js');
require('../../ground/scripts/buds/gr-video-bg.js');

/*!
 * jQuery Accordion / Tabs plugin
 * Author: Globalia
 * Licensed under the WTFPL license
 */

( function( $, window, document ) {

    "use strict";

    var pluginName = "accTabs",
        defaults = {
            breakpoint: 0,
            collapse: false
        };

    function Plugin ( element, options ) {
        this.name = pluginName;
        this.element = element;
        this.settings = $.extend( {}, defaults, options );
        this.init();
    }

    $.extend( Plugin.prototype, {
        init: function() {
            var instance = this;
            for( var i in defaults ){
                instance.settings[i] = $(instance.element).attr('gr-acctab-'+i) || instance.settings[i];
            }
            instance.breakpoint = ( isNaN(instance.settings.breakpoint) ? parseInt(window.sassBreakpoints[instance.settings.breakpoint], 10) : this.settings.breakpoint);

            $(instance.element).on('click', '> li > a:not([target])', function(event) {
                event.preventDefault();
                if (!$(this).hasClass('is-active')) {
                    $(instance.element).find('.is-open').removeClass('is-open');
                    $(this).next().addClass('is-open');
                    $(instance.element).find('.is-active').removeClass('is-active');
                    $(this).addClass('is-active');
                } else if(instance.settings.collapse){
                    $(instance.element).find('.is-open').removeClass('is-open');
                    $(instance.element).find('.is-active').removeClass('is-active');
                }
            });
        },
        callFunction: function (call, options) {
            if(typeof this[call] === 'function') {
                return this[call](options);
            }
        }
    });

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function(call, options ) {
        return this.each( function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new Plugin( this, options || call ) );
            } else {
                return $.data(this, "plugin_" + pluginName).callFunction(call, options);
            }
        });
    };

    $(function () {
        $(document).find('[gr-acctab]').accTabs();
    });

}) (require('jquery'), window, document );



( function( $, window, document, Cookies ) {
    "use strict";

    $('[gr-nav-trigger]').on('click', function(e) {
        e.preventDefault();
        $('.header__nav-wrapper').toggleClass("is-opened");
        $(this).toggleClass("is-opened");
        $('.header').toggleClass("is-opened");
    });

    $('[gr-search-toggle]').click(function(e) {
        e.preventDefault();
        $(this).parent().toggleClass("is-opened");
        if ($(this).parent().hasClass("is-opened")) $('input', $(this).parent()).focus();
    });

    $(document).on('click','.js-expend .filter-expend__title',function(e) {
        e.preventDefault();
        $(this).parent().toggleClass("is-opened");


    });

    $('.filters-wrapper__title').click(function() {
        $(this).parent().toggleClass('is-opened');
    });

    if (!Cookies.get('fixed-cta') || Cookies.get('fixed-cta') == 1) {
        $('.fixed-cta').addClass("is-opened");
    }

    $('.fixed-cta__header').click(function() {
        var parent = $(this).parent();
        parent.toggleClass("is-opened");

        if (parent.hasClass("is-opened")) {
            Cookies.set('fixed-cta', '1');
        }
        else {
            Cookies.set('fixed-cta', '0');
        }
    });

    /*window.sassBreakpoints = sassToJs(
        document.querySelector('.bp-map'),
        {
            pseudoEl: ':before',
            cssProperty: 'content',
            debug: true
        }
    );*/

    window.styleArray = [{
        featureType: 'all',
        stylers: [
            { saturation: -80 }
        ]
    },{
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            { hue: '#00ffee' },
            { saturation: 50 }
        ]
    },{
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
            { visibility: 'off' }
        ]
    }];

    $(window).on('scroll load', function() {
        if ($(window).scrollTop() > 10) {
            $(document).find('[gr-nav]').addClass('is-scrolled');
        } else {
            $(document).find('[gr-nav]').removeClass('is-scrolled');
        }
    });

    $(window).on('load', function() {
        if ($('.background__header-top-cover').length) {
            $('.background__header-top-cover').addClass('is-loaded');
        }
    });

    $('[name="fields[customerType]"]').on('load change', function() {
        console.log($(this).find('option:selected').val());
        if($(this).find('option:selected').val() == 'detaillant') {
            $(this).parents('.form-item').next().removeClass('is-hidden');
        } else {
            $(this).parents('.form-item').next().addClass('is-hidden');
        }
    }).triggerHandler('load');

    $('body').click(function(event){
        var expend = $('.filter-expend--absolute'),
            search = $('.header__search-container');

        expend.not(expend.has(event.target)).removeClass('is-opened');
        search.not(search.has(event.target)).removeClass('is-opened');
    });

    if ($(window).width() <= 767) {
        $(window).on('load', function() {
            $('[tooltip-expander]').expander();
        })
    }

}(jquery, window, document, require('./js-cookie.js')));
